import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import jwt_decode from 'jwt-decode';
import { TokenDetails } from "../models/token-details.model";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  authenticated = true;
  jwtToken: string;
  roles: Array<any>;

  constructor(
    private router: Router, 
    private http: HttpClient,
    public jwtHelper: JwtHelperService
    ) {
    this.checkAuth();
  }

  checkAuth() {
    this.authenticated = this.isAuthenticated();
  }

  getuser() {
    return of({});
  }

  signin(credentials) {
    return this.login(credentials);
  }

  signout() {
    this.logout();
    this.router.navigateByUrl("/sessions/signin");
  }

  login(user) {
    return this.http.post(environment.AUTH_API_URL + 'login', user, {observe: 'response'});
  }

  resetPassword(user) {
    return this.http.post(environment.AUTH_API_URL + 'account/reset-password', user, {observe: 'response'});
  }

  initPassword(user) {
    return this.http.post(environment.AUTH_API_URL + 'account/init-password', user, {observe: 'response'});
  }

  register(user) {
    return this.http.post(environment.AUTH_API_URL + 'account/sign-up', user, {observe: 'response'});
  }

  getUserDetails(): Observable<any> {
    return this.http.get(environment.AUTH_API_URL + 'account/user-details');
  }

  saveToken(jwtToken) {
    this.jwtToken = jwtToken;
    localStorage.setItem('token', jwtToken);
    const jwtDecode: any = jwt_decode(this.jwtToken);
    this.roles = jwtDecode.roles;
  }

  removeToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('user-details');
  }

  loadToken() {
    this.jwtToken = localStorage.getItem('token');
    return this.jwtToken;
  }

  logout() {
    this.removeToken();
  }

  isAdmin() {
    this.jwtToken = localStorage.getItem('token');
    const jwtDecode: any = jwt_decode(this.jwtToken);
    let roles = jwtDecode.roles;

    for (const r of roles) {
      if (r.authority === 'ADMIN') {
        return true;
      }
      return false;
    }
  }

  isSuperAdmin() {
    this.jwtToken = localStorage.getItem('token');
    const jwtDecode: any = jwt_decode(this.jwtToken);
    let roles = jwtDecode.roles;

    for (const r of roles) {
      if (r.authority === 'APP_ADMIN') {
        return true;
      }
      return false;
    }
  }

  getUserInfos() {
    const userDetails: any = JSON.parse(localStorage.getItem("user-details"));

    if (userDetails != null) {
      return {
        username: userDetails.userName,
        email: userDetails.login,
        dossier: userDetails.selectedDossier.intitule,
        collaborateurId: userDetails.collaborateurId
      };
    }

    //if not user details
    return null;
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    let flag: boolean = token != null && !this.jwtHelper.isTokenExpired(token);
    return flag;
  }

  getTokenDetails(res) {
    return {
      userName: res.username,
      login: res.login,
      collaborateur: {id: res.collaborateurId},
      principalDossier: {
        id: res?.principalDossierId,
        code: res.principalDossierCode,
        intitule: res.principalDossierIntitule
      },
      selectedDossier: {
        id: res.selectedDossierId,
        code: res.selectedDossierCode,
        intitule: res.selectedDossierIntitule
      },
      roles: res.roles
    };
  }

  saveUserDetails(res) {
    let details : TokenDetails = this.getTokenDetails(res);
    localStorage.setItem("user-details", JSON.stringify(details));
  }

}
