import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      canActivate: [AuthGaurd] ,
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'referentiel',
      loadChildren: () => import('./views/referentiel/referentiel.module').then(m => m.ReferentielModule)
    },
    {
      path: 'operations/stock',
      loadChildren: () => import('./views/stock/stock.module').then(m => m.StockModule)
    },
    {
      path: 'operations/achat',
      loadChildren: () => import('./views/achat/achat.module').then(m => m.AchatModule)
    },
    {
      path: 'operations/vente',
      loadChildren: () => import('./views/vente/vente.module').then(m => m.VenteModule)
    },
    {
      path: 'operations/reglement',
      loadChildren: () => import('./views/reglement/reglement.module').then(m => m.ReglementModule)
    },
    {
      path: 'administration',
      loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule)
    }
  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/v1',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarCompactComponent,
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  },
  {
    path: 'boutique/:code',
    loadChildren: () => import('./views/boutique/boutique.module').then(m => m.BoutiqueModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
