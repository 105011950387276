<div class="card o-hidden">
    <div class="row">
      <div class="col-md-12">
        <div class="p-4" *ngIf="userProfil">
          
          <h1 class="mb-3 text-18">Profil</h1>
          <div class="content">
            <div class="form-group profil-row">
              <label for="login">User name</label>
              <label id="login">{{username}}</label>
            </div>
            <div class="form-group profil-row">
              <label for="login">Login</label>
              <label id="login">{{userProfil.login}}</label>
            </div>
            <div class="form-group profil-row">
              <label for="collaborateurNom">Nom</label>
              <label id="collaborateurNom">{{userProfil.collaborateurNom}}</label>
            </div>
            <div class="form-group profil-row">
              <label for="collaborateurPrenom">Prénom</label>
              <label id="collaborateurPrenom">{{userProfil.collaborateurPrenom}}</label>
            </div>
            <div class="form-group profil-row">
              <label for="collaborateurPortable">Téléphone</label>
              <label id="collaborateurPortable">{{userProfil.collaborateurPortable}}</label>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>